<template>
    <div>
        <h1>Teste API Exlibris + axios</h1>
        <b-button class="m-1" @click="solicitarColecoes()" variant="outline-primary" style="width: fit-content">Solicitar</b-button>
        <div v-for="item in info" :key="item.id">
                <img :src="path+item.thumbnail" height='200'>
                <br>
                <strong>{{item.name}}</strong>
                <br>
                {{item.description}}
                <br>
                <a :href="'https://uninove.primo.exlibrisgroup.com/discovery/collectionDiscovery?vid=55UNINOVE_INST:UNINOVE&collectionId='+item.pid.value">Go to</a>
                <div v-for="sub in item.collection" :key="sub.id">
                    <img :src="path+sub.thumbnail" height='200'> {{sub.name}}
                </div>
            <hr>
        </div>
        <!-- l8xx577505eb657945849c751dae5ba7a3d5 -->
    </div>
</template>


<script>
import axios from 'axios'

export default {
    data(){
        return{
            teste: 'Olá',
            info: [],
            path: 'https://uninove.primo.exlibrisgroup.com/'
        }
    },
    methods: {
        solicitarColecoes(){
            // let resposta = []

        axios
        .get('https://cors-anywhere.herokuapp.com/https://api-eu.hosted.exlibrisgroup.com/almaws/v1/bibs/collections?level=2&apikey=l8xx577505eb657945849c751dae5ba7a3d5')
        .then(response => (this.info = response.data.collection))

            // var config = {
            //     method: 'get',
            //     url: 'https://cors-anywhere.herokuapp.com/https://api-na.hosted.exlibrisgroup.com/almaws/v1/electronic/e-collections?limit=10&offset=0&apikey=l8xx577505eb657945849c751dae5ba7a3d5',
            //     headers: {
            //         "Access-Control-Allow-Origin": "*",
            //         "Access-Control-Allow-Methods": "get",
            //         "x-api-key": "l8xx577505eb657945849c751dae5ba7a3d5",
            //         "Content-Type": "application/json",
            //         "Accept": "application/json"
            //         // // "connection": "keep-alive",
            //         // "content-encoding": "gzip",
            //         // "content-type": "application/json;charset=UTF-8",
            //         // "p3p": "CP=\"IDC DSP COR ADM DEVi TAIi PSA PSD IVAi IVDi CONi HIS OUR IND CNT\"",
            //         // "server": "CA-API-Gateway/9.0",
            //         // // "transfer-encoding": "chunked",
            //         // "vary": "accept-encoding, Accept-encoding",
            //         // "x-exl-api-remaining": "59990",
            //         // "x-final-url": "https://api-na.hosted.exlibrisgroup.com/almaws/v1/bibs/collections?level=2&apikey=l8xx577505eb657945849c751dae5ba7a3d5",
            //         // "x-request-id": "djCHKVTcBB",
            //     },
            // };

            // // Add Your Key Here!!!
            // axios.defaults.headers.common = {
            // "X-API-Key": "l8xx577505eb657945849c751dae5ba7a3d5",
            // };

            // axios(config)
            // .then(function (response) {
            //     console.log(this.teste)
            //     // console.log(JSON.stringify(response.data));
            //     // console.log('1: ',response);
            //     // console.log('2: ',response.data);
            //     // console.log('3: ',response.status);
            //     // console.log('4: ',response.statusText);
            //     // console.log('5: ',response.headers);
            //     // console.log('6: ',response.config);
            //     this.info = JSON.stringify(response.data)
            // })
            // .catch(function (error) {
            //     if (error.response) {
            //     // The request was made and the server responded with a status code
            //     // that falls out of the range of 2xx
            //     console.log('Erro 1: ',error.response.data);
            //     console.log('Erro 2: ',error.response.status);
            //     console.log('Erro 3: ',error.response.headers);
            //     console.log(error);
            //     } else if (error.request) {
            //     // The request was made but no response was received
            //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            //     // http.ClientRequest in node.js
            //     console.log(error.request);
            //     } else {
            //     // Something happened in setting up the request that triggered an Error
            //     console.log('Error', error.message);
            //     }
            //     console.log(error.config);
            // });
            // console.log(resposta)
        }
    }
}
</script>