<template>
  <div class="home container">
      <h1>Casos Clínicos</h1>
        <ul>
            <li><router-link to="#">{{Home}}</router-link></li>
        </ul>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {

  },
  data() {
      return {
        cursos: [
                {
                    titulo: 'IAM com supra',
                    texto_caso: '',
                    resposta: {
                        precricao: [
                            
                        ],
                        observacoes: ''
                    }
                }
            ]
      }
  }
  // methods: {
  //   searchPrimo: function(){
  //     document.getElementById("primoQuery").value = "any,contains," + document.getElementById("primoQueryTemp").value.replace(/[,]/g, " ");
  //     document.forms["searchForm"].submit();
  //   }
  // }
}
</script>

<style scoped>
.hero {
  background-color: #1961D3;
  padding: 40px 0 40px 0;
  max-height: 50vh;
}
.hero-text{
  text-align: left; 
  color:#fff; 
  font-weight: 800; 
  font-size: 4rem;
}
.container-fluid.content-wrap {
  max-width: 1320px;;
}

.search-bar {
  border: 1px solid 686868;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  color: #2c3e50;
  top: -50px;
  box-shadow: var(--shadow) 0px 3px 8px;
}

form select{
  width: 100px !important;
  flex: none;
}

form input{
  width: 500px !important;
  flex: none;
}


@media screen and (max-width: 900px){
    .container-fluid {
      padding: 5px;
    }
    .hero {
      padding: 10px 0 10px 0;
      max-height: 30vh;
    }
    .hero-text{
      text-align: left; 
      color:#fff; 
      font-weight: 600; 
      font-size: 1.5rem;
    }
}
@media screen and (max-width: 500px){
    .container-fluid {
      padding: 7px;
    }
}
</style>

